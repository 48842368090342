import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import WknSwiper from '../modules/wkn-swiper'
gsap.registerPlugin(DrawSVGPlugin)
import Swiper from 'swiper'

export default class FrontPage extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Home`)
    if (isDEVMODE) console.log(viewStorage.current)

    // DOM
    this.DOM = {}

    this.ctx = gsap.context((self) => {}, viewStorage.current)
    this.mm = gsap.matchMedia();

    this.initSwiperVideo()
    this.initSwiperGames()
    this.initVideoScroll()
    this.initRooms()
  }

  initSwiperVideo() {
    if (isDEVMODE) console.log('Swiper Video')
  
    this.ctx.add((self) => {
      const section = self.selector('div.Page__hero')[0]
      if (section === undefined) return
  
      // Initialiser Swiper
      const videoSwiper = new WknSwiper(section.querySelector('.swiper'), { 
        slidesPerView: 1, 
        navigation: {
          nextEl: '.next',
          prevEl: '.prev',
        },
        loop: true
      });
  
      const swiperInstance = videoSwiper.swiper || videoSwiper.instance || videoSwiper;
      const videos = section.querySelectorAll('.swiper-slide video');
      const pauseButton = document.querySelector('.pause');
      const progressLine = pauseButton.querySelector('svg rect')
      let isPaused = false;
      let progressAnimation = null
      let activeVideo = videos[0]
  
      // Écouter l'événement 'ended' pour passer à la diapositive suivante
      videos.forEach((video) => {
        video.addEventListener('ended', () => {
          swiperInstance.slideNext();
        });
      });
  
      // Gérer le changement de diapositive
      swiperInstance.on('slideChange', () => {
        // Prev Vidéo
        activeVideo.pause()
        activeVideo.currentTime = 0;

        // Active vidéo
        const activeSlideIndex = swiperInstance.realIndex;
        activeVideo = videos[activeSlideIndex];
        activeVideo.play().catch((error) => {
          console.log('Error playing the video:', error);
        });

        // Progression
        progressAnimation && progressAnimation.kill()
        progressAnimation = gsap.fromTo(progressLine, {drawSVG: '0%'}, {duration: activeVideo.duration, drawSVG: '96%', ease:'none'});
      });
  
      // Gérer le bouton pause
      pauseButton.addEventListener('click', () => {
        if (activeVideo) {
          console.log(progressAnimation)
          if (isPaused) {
            activeVideo.play();
            progressAnimation.resume()
          } else {
            activeVideo.pause();
            progressAnimation.pause()
          }
          isPaused = !isPaused;
        }
      });

      // Progression
      gsap.set(progressLine, {drawSVG: '0%'})
      activeVideo.play()
      progressAnimation = gsap.fromTo(progressLine, {drawSVG: '0%'}, {duration: activeVideo.duration, drawSVG: '96%', ease:'none'});
  
      return () => {
        videoSwiper.destroy();
      }
    })
  }

  initRooms() {
    if (isDEVMODE) console.log('Init Rooms')

      // Breakpoint
    const breakpoint = 768
    // Responsive object
    const responsiveObj = {
      isDesktop: `(min-width: ${breakpoint}px)`,
      isMobile: `(max-width: ${breakpoint - 1}px)`
    }

    this.mm.add(responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      const section = context.selector('.Room')[0]
      if (section === undefined) return

      const cards = section.querySelectorAll('.card')

      const onMouseEnter = (e) => {
        e.preventDefault();
        const cardTxt = e.currentTarget.querySelector('.card__text')
        const pHeight = e.currentTarget.querySelector('p').offsetHeight
        gsap.set(cardTxt, { y : -pHeight })
      }

      const onMouseLeave = (e) => {
        e.preventDefault();
        const cardTxt = e.currentTarget.querySelector('.card__text')
        gsap.set(cardTxt, { y : 0 })
      }

      if (isDesktop) {
        console.log('isDesktop')
        cards.forEach(card => {
          card.addEventListener('mouseenter', onMouseEnter)
          card.addEventListener('mouseleave', onMouseLeave)
        })
      } else {
        console.log('isMobile')
        cards.forEach(card => {
          card.removeEventListener('mouseenter', onMouseEnter)
          card.removeEventListener('mouseleave', onMouseLeave)
        })
      }

      return () => { 
        cards.forEach(card => {
          card.removeEventListener('mouseenter', onMouseEnter)
          card.removeEventListener('mouseleave', onMouseLeave)
        })
      }

    }, viewStorage.current)
  }

  initVideoScroll() {
    if (isDEVMODE) console.log('Init VideoScroll')

    this.ctx.add((self) => {
      const section = self.selector('section.Characteristics')[0]
      if (section === undefined) return
      const videoContainer = section.querySelector('.Characteristics__bloc')
      const video = section.querySelector('video')

      const videoTL = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: videoContainer,
          start: "center center",
          end: "+=250%",
          pin: true,
          scrub: true,
          marker: true,
        }
      })

      // Setup SRC
      video.src = video.dataset.src

      const forcetoPause = () => {
        video.removeAttribute("autoplay");
        video.currentTime = 0;
        video.pause();

        videoTL.fromTo( video,
          { currentTime: 0 },
          { currentTime: video.duration || 1 }, 0
        )
      }

      video.addEventListener("loadeddata", forcetoPause);
      videoTL.fromTo( video,
        { opacity: 1, yPercent: 0 },
        { opacity: 0, yPercent: -20, duration: 0.4, ease: 'power1.inOut' }, 0.6
      )

      return () => {
        videoTL.kill()
      }
    })
  }

  initSwiperGames() {
    if (isDEVMODE) console.log('Swiper Games')

    this.ctx.add((self) => {
      const section = self.selector('section.Games')[0]
      if (section === undefined) return

      const thumbsSwiper = new Swiper(section.querySelector('.thumbs'), {
        spaceBetween: 19,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      });

      const gamesSwiper = new WknSwiper(section.querySelector('.swiper'), { 
        slidesPerView : 1, 
        loop: true,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
        navigation: {
          nextEl: '.next',
          prevEl: '.prev',
        },
        thumbs: {
          swiper: thumbsSwiper,
        },
      })

      return () => {
        thumbsSwiper.destroy()
        gamesSwiper.destroy()
      }
    })
  }

  onLeaveCompleted() {
    this.ctx && this.ctx.revert()
    this.mm && this.mm.revert()
  }
}
